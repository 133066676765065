/**
 * NOTE: any colors added in `/public/odo/colors.css`
 * must be added to the below array as well (without the `--odo-color-` prefix)
 */
const colorNames = [
  // grey scale
  'pure-white',
  'white',
  'off-white',
  'black',
  'dark-grey',
  'grey',
  'grey-light',
  'grey-ultra-light',
  'grey-blue',

  // colors w muted
  'palette-blue',
  'palette-blue-light',
  'palette-blue-muted',
  'palette-green',
  'palette-green-light',
  'palette-green-muted',
  'palette-turquoise',
  'palette-turquoise-light',
  'palette-turquoise-muted',
  'palette-violet',
  'palette-violet-light',
  'palette-violet-muted',
  'palette-yellow',
  'palette-yellow-light',
  'palette-yellow-muted',
  'palette-pink',
  'palette-pink-light',
  'palette-pink-muted',
  'palette-red',
  'palette-red-light',
  'palette-red-muted',

  // named
  'foreground',
  'foreground-alt',
  'background',
  'text',
  'text-muted',
  'text-light',
  'border',
  'primary',
  'secondary',
  'highlight',
  'accent',
  'muted',
  'success',
  'warning',
  'error',
  'info',
] as const;

// NOTE: once we no longer have any of RedPandas CSS variables we can remove the `odo` part of this prefix.
const VARIABLE_PREFIX = '--odo-color-';

/**
 * This little trick allows us to have a type limited to the above array options.
 *
 * NOTE: we could've made it a union type instead,
 * but the array based version feels better to maintain and supports other uses as well.
 */
export type CssColor = typeof colorNames[number];

export const cssColor = (name: CssColor) => `var(${VARIABLE_PREFIX}${name})`;

const colorsObj: Record<string, string> = {};
colorNames.forEach(name => (colorsObj[name] = cssColor(name)));

// for use in a theme file
export const colors = { ...colorsObj };
